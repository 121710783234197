import React from 'react';
import {Column} from 'primereact/column';
import {Tooltip} from 'primereact/tooltip';
import {MenuItemOptions} from 'primereact/menuitem';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {Dropdown} from 'primereact/dropdown';
import {Toast} from 'primereact/toast';
import {
  AppContext,
  AppColumnMenuBodyTemplate,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoDataTable,
  MessageService,
  ToastService,
  TwoMessage,
  TleReferenceComponent,
  UsersService,
  getCompanyDescription,
} from 'two-app-ui';
import {
  FreightOrder,
  QueryParameter,
  FreightStage,
  MapOf,
  Location,
  FreightOrderPatch,
  Run,
  User,
  Address,
} from 'two-core';
import FreightOrdersService from '../../services/FreightOrdersService';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Subscription} from 'rxjs';
import config from '../../config/config';
import AssignOrdersToRunDialog from './AssignOrdersToRunDialog';
import './Orders.scss';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {NavLink} from 'react-router-dom';
import {messages} from '../../config/messages';
import OrdersService from '../../services/OrdersService';
import TlesService from '../../services/TlesService';
import '../../scss/CustomTable.scss';
import {StageDropdownOption, stages} from '../Order/Constants/constants';
import {faPersonCarryBox} from '@fortawesome/pro-regular-svg-icons';
import {faBars} from '@fortawesome/pro-regular-svg-icons';
import ChangeLocationDialog from './ChangeLocationDialog';
import TasksService from '../../services/TasksService';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RunReferenceComponent from '../Reference/RunReferenceComponent';
import DeliverUndeliverDialog, {DeliverUndeliverDialogType} from './DeliverUndeliverDialog';
import {faMessageExclamation} from '@fortawesome/pro-light-svg-icons';
import LocationsService from '../../services/LocationsService';
import {getFreightOrderAgeInDays} from '../../utils/FreightOrderUtil';
import {DateTime} from 'luxon';

export const productLines: string[] = ['Colourvue', 'Shadesol', 'Curtains', 'Shutters'];

interface Props {
  customMenuItems?: (selectedOrders: FreightOrder[]) => AppMenuItem[];
  customTopMenuItems?: (selectedOrders: FreightOrder[]) => AppMenuItem[];
  hideMenu?: boolean;
  isAll?: boolean;
  freightOrderExcludedStages?: FreightStage[];
  freightOrderIds?: string[] | undefined;
  freightOrderExcludedIds?: string[] | undefined;
  runIds?: string[] | undefined;
  id?: string;
  droppableId?: string;
  customEmptyMessage?: string;
  heightToScroll?: string;
  locations?: Location[];
  changeDestinationLocation?: (locationId: number, orderId: string) => void;
  ordersDestinationLocations?: MapOf<number>;
  runDestinationLocationsDisabled?: boolean;
  subtableOfTable?: string;
  hideFilter?: boolean;
  hideSort?: boolean;
  isGroupped?: boolean;
  hidePaging?: boolean;
  columnHeaderClassName?: string;
  selectionColumnClassName?: string;
  paginatorClassName?: string;
  selectedOrders?: FreightOrder[];
  setSelectedOrders?: (groupId: string, orders: FreightOrder[]) => void;
  groupId?: string;
  currentRun?: Run; //This is for orders table in run's detail.
  stopId?: number;
}

interface State {
  loading: boolean;
  items: FreightOrder[];
  selectedItems: FreightOrder[];
  totalItems: number;
  activeFilters: {};
  filters: {
    id: string;
    reference: string;
    company: string;
    product_line: string;
    freight_stage: string;
    state_name: string;
    location: string;
    container: string;
    destination: string;
    run: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAssignToRunDialog: boolean;
  showChangeCurrentLocationDialog: boolean;
  showDeliverDialog: boolean;
  deliverDialogType: DeliverUndeliverDialogType;
  locationsMap: MapOf<Location>; //This is for 'from'/'to' columns  in orders table in run's detail.
  usersMap: MapOf<User>;
}

class OrderListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  tasksService: TasksService | null = null;
  toastService: ToastService | null = null;
  locationsService: LocationsService | null = null;
  usersService: UsersService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: true,
      activeFilters: {},
      filters: {
        id: '',
        reference: '',
        company: '',
        product_line: '',
        freight_stage: '',
        state_name: '',
        location: '',
        container: '',
        destination: '',
        run: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAssignToRunDialog: false,
      showChangeCurrentLocationDialog: false,
      showDeliverDialog: false,
      deliverDialogType: 'deliver',
      locationsMap: {},
      usersMap: {},
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.initTopMenuItems = this.initTopMenuItems.bind(this);
    this.dealerBodyTemplate = this.dealerBodyTemplate.bind(this);
    this.ageBodyTemplate = this.ageBodyTemplate.bind(this);
    this.sizeBodyTemplate = this.sizeBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.stateBodyTemplate = this.stateBodyTemplate.bind(this);
    this.locationBodyTemplate = this.locationBodyTemplate.bind(this);
    this.destinationBodyTemplate = this.destinationBodyTemplate.bind(this);
    this.runBodyTemplate = this.runBodyTemplate.bind(this);
    this.productLineBodyTemplate = this.productLineBodyTemplate.bind(this);
    this.referenceBodyTemplate = this.referenceBodyTemplate.bind(this);
    this.changeDestinationBodyTemplate = this.changeDestinationBodyTemplate.bind(this);
    this.showAssignOrdersToRunDialog = this.showAssignOrdersToRunDialog.bind(this);
    this.getSelectedItems = this.getSelectedItems.bind(this);
    this.draggableBodyTemplate = this.draggableBodyTemplate.bind(this);
    this.onDeliverUndeliverContinue = this.onDeliverUndeliverContinue.bind(this);
    this.onDeliverUndeliverCancel = this.onDeliverUndeliverCancel.bind(this);
    this.getLocationName = this.getLocationName.bind(this);
    this.fromBodyTemplate = this.fromBodyTemplate.bind(this);
    this.toBodyTemplate = this.toBodyTemplate.bind(this);
    this.lastActivityBodyTemplate = this.lastActivityBodyTemplate.bind(this);
  }

  async componentDidMount() {
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.ordersService = this.context.ordersService;
    this.tasksService = this.context.tasksService;
    this.toastService = this.context.toastService;
    this.locationsService = this.context.locationsService;
    this.usersService = this.context.usersService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (
        message === messages.runOrderCreate ||
        message === messages.containerUpdate ||
        message === messages.orderUpdated ||
        message === messages.proofOrdersChanged ||
        (message === messages.ordersAssignToRun &&
          (this.props.groupId === 'assigned-orders' || this.props.groupId === 'orders-to-assign'))
      ) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          this.loadData();
        }
      }
    });
    this.loadData();
    this.loadUsers();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const state = localStorage.getItem('current state') ?? '';

    const defaultFilters: string[] = [];
    const filters: string[] = [];
    let sortBy: string[] | undefined = [];

    if (this.state.filters.company) {
      filters.push(
        JSON.stringify({
          field: 'company.account_number',
          value: this.state.filters.company,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'order.reference',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.destination) {
      filters.push(
        JSON.stringify({
          field: "order.shipping_address->>'suburb'",
          value: this.state.filters.destination,
          condition: 'iLike',
        })
      );
    }

    if (this.props.freightOrderIds) {
      const orderIds = this.props.freightOrderIds;
      defaultFilters.push(
        JSON.stringify({
          field: 'id',
          value: orderIds,
          condition: 'in',
        })
      );
    }

    if (this.props.freightOrderExcludedIds) {
      const orderIds = this.props.freightOrderExcludedIds;
      defaultFilters.push(
        JSON.stringify({
          field: 'id',
          value: orderIds,
          condition: 'notIn',
        })
      );
    }

    if (this.props.runIds) {
      const runIds = this.props.runIds;
      defaultFilters.push(
        JSON.stringify({
          field: 'run.id',
          value: runIds,
          condition: 'in',
        })
      );
    }

    if (this.props.stopId) {
      const stopId = this.props.stopId;
      defaultFilters.push(
        JSON.stringify({
          field: 'task.stop_id',
          value: stopId,
        })
      );
    }

    if (this.props.freightOrderExcludedStages) {
      const excludedStages = this.props.freightOrderExcludedStages;
      defaultFilters.push(
        JSON.stringify({
          field: `route->'${state}'->>'stage'`,
          value: excludedStages,
          condition: 'notIn',
        })
      );
    }

    if (this.state.filters.id) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.id,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.state_name && this.state.filters.state_name.length) {
      filters.push(
        JSON.stringify({
          field: 'location.state_id',
          value: this.state.filters.state_name,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.freight_stage && this.state.filters.freight_stage.length) {
      filters.push(
        JSON.stringify({
          field: `route->'${state}'->>'stage'`,
          value: this.state.filters.freight_stage,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.location) {
      filters.push(
        JSON.stringify({
          field: 'location.name',
          value: this.state.filters.location,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.container) {
      filters.push(
        JSON.stringify({
          field: 'container.id',
          value: this.state.filters.container,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.run) {
      filters.push(
        JSON.stringify({
          field: 'run.name',
          value: this.state.filters.run,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.product_line && this.state.filters.product_line.length) {
      filters.push(
        JSON.stringify({
          field: 'factory_order.product_line',
          value: this.state.filters.product_line,
          condition: 'in',
        })
      );
    }

    this.setState({activeFilters: {...filters}});
    filters.push(...defaultFilters);

    const field = this.state.sortBy?.field;
    let sortByField = field ?? 'handed_over';
    switch (sortByField) {
      case 'id':
        sortByField = 'id';
        break;
      case 'reference':
        sortByField = 'order.reference';
        break;
      case 'owner_company.account_number':
        sortByField = 'company.account_number';
        break;
      case 'product_line':
        sortByField = 'factory_order.product_line';
        break;
      case 'destination':
        sortByField = "order.shipping_address->>'suburb'";
        break;
      case 'size':
        sortByField = 'order.size';
        break;
      case 'age':
        sortByField = 'handed_over';
        break;
      case 'location':
        sortByField = 'location.name';
        break;
      case 'container':
        sortByField = 'container.id';
        break;
      case 'stage':
        sortByField = `route->'${state}'->>'stage'`;
        break;
      case 'state':
        sortByField = 'location.state_id';
        break;
      case 'last_activity':
        sortByField = 'tle.id';
        break;
    }

    if (sortByField === '') {
      sortBy = undefined;
    } else {
      sortBy.push(
        JSON.stringify({
          field: sortByField,
          direction:
            field !== 'age'
              ? this.state.sortBy?.order === 1
                ? 'ASC'
                : 'DESC'
              : this.state.sortBy?.order === 1
                ? 'DESC'
                : 'ASC',
        })
      );
    }

    if (!filters.length) {
      const threeMonthsAgo = DateTime.now().minus({months: 3});
      filters.push(
        JSON.stringify({
          field: 'order.submitted_at',
          value: threeMonthsAgo.toISO(),
          condition: '>',
        })
      );
    }

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      orderBys: sortBy,
      offset: this.props.hidePaging ? undefined : this.state.pagination.offset,
      page_size: this.props.hidePaging ? undefined : this.state.pagination.pageSize,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data?.records as FreightOrder[]) ?? [];

        this.handleSelectedItems(dataRecords);

        const currentRun = this.props.currentRun;
        if (currentRun) {
          //find current run location ids
          const locationIds = currentRun.stops?.map(stop => stop.location_id).flatMap(id => id!) ?? [];
          this.loadLocations(locationIds);
        }

        this.setState({
          items: dataRecords,
          totalItems: data?.total_records ?? 0,
          loading: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
      });
  }

  loadLocations(ids: number[]) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      showAll: true,
    };
    this.locationsService?.getLocations(params).then(data => {
      const locations = data.records as Location[];
      const locationsMap: MapOf<Location> = {};
      for (const location of locations) {
        locationsMap[location.id!] = location;
      }
      this.setState({
        locationsMap: locationsMap,
      });
    });
  }
  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };
    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];
        const usersMap: MapOf<User> = {};
        for (const user of users) {
          usersMap[user.id!] = user;
        }

        this.setState({
          usersMap: usersMap,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async setUnloadedFreightOrders() {
    this.setState({loading: true});
    const freightOrders: FreightOrder[] = this.getSelectedItems();

    Promise.all(
      freightOrders.map((freightOrder: FreightOrder) => {
        const unloadLocationId = freightOrder?.container?.unload_at_id;

        if (unloadLocationId) {
          const newStage: FreightStage =
            Number(unloadLocationId) === Number(freightOrder.final_destination_id) ? 'Delivered' : 'At Warehouse';

          const state = localStorage.getItem('current state')!;
          const routePart = {...freightOrder.route![state], stage: newStage};
          const route = {...freightOrder.route, [state]: routePart};
          const updateOrder: FreightOrderPatch = {
            route: route,
            current_location_id: unloadLocationId,
            at_current_location_since: new Date(),
          };

          return this.freightOrdersService?.updateFreightOrder(freightOrder.id ?? '', updateOrder);
        }
        return;
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Orders unloaded successfully.');
        this.setState({loading: false});
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Orders unload failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.getSelectedItems();
    const selectedItemsCount = selectedItems.length;

    if (selectedItemsCount > 0) {
      return this.props.customMenuItems
        ? this.props.customMenuItems(selectedItems)
        : this.initOtherMenuItems(selectedItems);
    }
    return [];
  }

  initTopMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    if (this.props.isGroupped) {
      // system show top menu for orders in parent table
      return [];
    }

    return this.props.customTopMenuItems ? this.props.customTopMenuItems(selectedItems) : this.initMenuItems();
  }

  initOtherMenuItems(selectedItems: FreightOrder[]): AppMenuItem[] {
    const otherMenuItems: AppMenuItem[] = [];
    const state = localStorage.getItem('current state')!;

    if (selectedItems.every(order => order.route?.[state]?.stage !== 'Delivered')) {
      const assignRunMenu: AppMenuItem = {
        label: 'Assign Run',
        faIcon: ['fas', 'truck-moving'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAssignOrdersToRunDialog();
        },
      };
      otherMenuItems.push(assignRunMenu);

      const deliveredMenu: AppMenuItem = {
        label: 'Delivered',
        faIcon: faPersonCarryBox,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showDeliverUndeliverDialog('deliver');
        },
      };
      otherMenuItems.push(deliveredMenu);
    }

    const changeLocationMenu: AppMenuItem = {
      label: 'Change Current Location',
      faIcon: ['fas', 'warehouse'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showChangeCurrentLocationDialog();
      },
    };
    otherMenuItems.push(changeLocationMenu);

    if (
      (this.props.isAll || this.props.subtableOfTable === 'containers') &&
      selectedItems.every(order => order.route?.[state]?.stage === 'In Container')
    ) {
      const hasSameContainer = selectedItems.every(
        (val, i, arr) => val.container?.unload_at_id === arr[0].container?.unload_at_id
      );

      if (hasSameContainer) {
        const unloadedMenu: AppMenuItem = {
          label: 'Unloaded',
          faIcon: ['fas', 'truck-loading'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setUnloadedFreightOrders();
          },
        };

        otherMenuItems.push(unloadedMenu);
      }
    }
    if (selectedItems.every(order => order.route?.[state]?.stage === 'Delivered')) {
      const isNotInDealerLocation = selectedItems.every(freightOrder => freightOrder.location?.type !== 'dealership');
      if (isNotInDealerLocation) {
        const unloadedMenu: AppMenuItem = {
          label: 'Un-Deliver',
          faIcon: ['fas', 'truck-loading'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.showDeliverUndeliverDialog('undeliver');
          },
        };

        otherMenuItems.push(unloadedMenu);
      }
    }
    return otherMenuItems;
  }

  showAssignOrdersToRunDialog() {
    this.setState({showAssignToRunDialog: true});
  }

  showChangeCurrentLocationDialog() {
    this.setState({showChangeCurrentLocationDialog: true});
  }

  showDeliverUndeliverDialog(dialogType: DeliverUndeliverDialogType): void {
    this.setState({showDeliverDialog: true, deliverDialogType: dialogType});
  }

  onDeliverUndeliverContinue(): void {
    this.setState({showDeliverDialog: false});
    this.loadData();
  }

  onDeliverUndeliverCancel(): void {
    this.setState({showDeliverDialog: false});
  }

  handleSelectedItems(allItems: FreightOrder[]) {
    const selectedItems = this.getSelectedItems();
    const items: FreightOrder[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: FreightOrder[]) {
    if (this.props.setSelectedOrders && this.props.groupId) {
      this.props.setSelectedOrders(this.props.groupId, items);
    } else {
      this.setState({selectedItems: items});
    }
  }

  getSelectedItems(): FreightOrder[] {
    return this.props.selectedOrders?.length ? [...this.props.selectedOrders] : [...this.state.selectedItems];
  }

  async setChangeSelectedItem(item: FreightOrder) {
    const items = [...this.getSelectedItems()];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  dealerBodyTemplate(rowData: FreightOrder) {
    const owner = rowData.owner_company!;
    const tooltipElement = (
      <div className="p-d-flex p-flex-column">
        <span>{owner.account_number}</span>
        <span>{owner.name}</span>
        <span>{owner.trading_as}</span>
      </div>
    );

    return this.props.hideMenu ? (
      <>
        <span>{rowData.owner_company?.account_number}</span>
      </>
    ) : (
      <div>
        <AppColumnMenuBodyTemplate
          key={rowData.id}
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={this.initMenuItems}
          selectedItems={this.getSelectedItems()}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <span id={`dealer-${rowData.id}`}>{getCompanyDescription(owner)}</span>
        </AppColumnMenuBodyTemplate>
        <Tooltip
          className="custom-tooltip"
          target={`#dealer-${rowData.id}`}
          position="right"
          showDelay={500}
          mouseTrack
          mouseTrackLeft={15}
        >
          {tooltipElement}
        </Tooltip>
      </div>
    );
  }

  getListStyle = () => ({
    padding: 0,
  });

  referenceBodyTemplate(rowData: FreightOrder) {
    const noteElement = rowData.delivery_note?.length ? (
      <div className="p-as-center">
        <Tooltip target={`#note-${rowData.id}`}>{rowData.delivery_note}</Tooltip>
        <FontAwesomeIcon className={'p-ml-1'} id={`note-${rowData.id}`} icon={faMessageExclamation} />
      </div>
    ) : undefined;

    return (
      <div className="p-d-flex">
        {noteElement}
        <div>
          <NavLink to={'/order/' + rowData.id}>{rowData.order?.reference}</NavLink>
        </div>
      </div>
    );
  }

  draggableBodyTemplate(rowData: FreightOrder) {
    const data = JSON.stringify(rowData);
    return (
      <React.Fragment>
        <Draggable key={rowData.id} draggableId={data} index={0} disableInteractiveElementBlocking={true}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...this.getListStyle(),
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
          )}
        </Draggable>
      </React.Fragment>
    );
  }

  sizeBodyTemplate(rowData: FreightOrder) {
    const orderShipmentItems = rowData.shipment_items?.filter(si => si !== null) ?? [];

    const tooltipElement = (
      <div className="p-d-flex p-flex-column">
        {orderShipmentItems &&
          orderShipmentItems.map(shipmentItem => {
            const dimension = shipmentItem.dimensions ?? {};

            return (
              <div className="p-d-flex p-flex-row p-col-12 p-p-0 p-mb-1" key={shipmentItem.id}>
                <span className="p-d-flex p-as-center p-col-2 p-p-0">{`${shipmentItem.index} of ${shipmentItem.count}:`}</span>
                <span className="p-d-flex p-as-center p-col-5 p-p-0">{`${shipmentItem.content} `}</span>
                <span className="p-d-flex p-as-center p-jc-end p-col-3 p-p-0">{` ${dimension.height}x${dimension.width}x${dimension.depth} `}</span>
                <span className="p-d-flex p-as-center p-jc-end p-col-2 p-p-0">{`${dimension.weight ?? 0} kg`}</span>
              </div>
            );
          })}
      </div>
    );

    return (
      <>
        <Tooltip className="custom-tooltip" target={`#size-${rowData.id}`} position="right">
          {tooltipElement}
        </Tooltip>
        <span id={`size-${rowData.id}`}>{orderShipmentItems.length}</span>
      </>
    );
  }

  stageBodyTemplate(rowData: FreightOrder) {
    const freightOrder = this.state.items.find(fo => fo.id === rowData.id);
    const state = localStorage.getItem('current state')!;
    const stage = freightOrder?.route?.[state]?.stage ?? '';
    return <span className={`stage-badge stage-${stage.toLowerCase().replaceAll(' ', '-')}`}>{stage}</span>;
  }

  ageBodyTemplate(freightOrder: FreightOrder) {
    return getFreightOrderAgeInDays(freightOrder);
  }

  stateBodyTemplate(rowData: FreightOrder) {
    return <span>{rowData.location?.location_state?.long_name ?? ''}</span>;
  }

  locationBodyTemplate(rowData: FreightOrder) {
    const state = localStorage.getItem('current state') ?? '';
    const stage = rowData?.route?.[state]?.stage ?? '';

    if (stage === 'On Board') {
      return <span>{rowData.vehicle?.name}</span>;
    } else {
      if (rowData.location) {
        if (rowData.location.state_id === state) {
          return <span>{rowData.location.name}</span>;
        } else {
          return <span>{`${rowData.location.name} ${rowData.location.state_id}`}</span>;
        }
      }
      return '';
    }
  }

  destinationBodyTemplate(rowData: FreightOrder) {
    const state = localStorage.getItem('current state') ?? '';
    const shippingAddress = rowData.order?.shipping_address as Address;
    if (shippingAddress && shippingAddress.suburb) {
      if (shippingAddress.state && shippingAddress.state !== state) {
        return <span>{`${shippingAddress.suburb} ${shippingAddress.state}`}</span>;
      } else {
        return <span>{shippingAddress.suburb}</span>;
      }
    }
    return <></>;
  }

  runBodyTemplate(rowData: FreightOrder) {
    const order = {...rowData};
    const {currentRun} = this.props;
    if (currentRun) {
      //current run will not show in reference component
      order.runs = rowData.runs?.filter(run => run.id !== currentRun.id);
    }
    return (
      <div className="p-d-flex p-flex-row p-flex-wrap">
        <RunReferenceComponent order={order} />
      </div>
    );
  }
  productLineBodyTemplate(rowData: FreightOrder) {
    let prodLine = rowData.factory_order?.product_line;
    if (!prodLine) {
      if (rowData.id?.includes('RB-')) {
        prodLine = 'Blinds';
      } else {
        prodLine = 'Shutters';
      }
    }
    return <span>{prodLine}</span>;
  }

  changeDestinationBodyTemplate(rowData: FreightOrder) {
    const ownerId = rowData.order?.owner?.toString();
    const allLocations = this.props.locations ?? [];
    const locations =
      allLocations?.filter(
        l =>
          l.type === 'warehouse' ||
          l.type === 'factory' ||
          (l.type === 'dealership' && l.dealership_id?.toString() === ownerId)
      ) ?? [];

    const state = localStorage.getItem('current state') ?? '';
    const finalLocationId = rowData?.route?.[state].handover_location_id ?? 0;

    const id = rowData.id ?? '';
    const selectedLocationId = this.props.ordersDestinationLocations
      ? (this.props.ordersDestinationLocations[id] ?? finalLocationId)
      : finalLocationId;

    const existingLocation = locations?.find(l => l.id === selectedLocationId);

    if (!existingLocation) {
      const selectedLocation = allLocations.find(l => l.id === selectedLocationId);
      if (selectedLocation) {
        locations.push(selectedLocation);
      }
    }

    const existingFinalLocation = locations?.find(l => l.id === finalLocationId);

    if (!existingFinalLocation) {
      const finalLocation = allLocations.find(l => l.id === finalLocationId);
      if (finalLocation) {
        locations.push(finalLocation);
      }
    }

    return (
      <Dropdown
        className="p-d-flex w-100"
        filter
        value={Number(selectedLocationId)}
        options={locations}
        onChange={e => this.setSelectedLocation(e, rowData)}
        optionLabel="name"
        optionValue="id"
        valueTemplate={this.locationOptionTemplate}
        itemTemplate={this.locationOptionTemplate}
        disabled={this.props.runDestinationLocationsDisabled}
      />
    );
  }

  lastActivityBodyTemplate(rowData: FreightOrder) {
    const lastTle = rowData?.last_tle;
    if (lastTle?.id) {
      const user = this.state.usersMap[lastTle.recorded_by];
      return <TleReferenceComponent identifier={rowData.id ?? ''} value={lastTle} user={user} />;
    } else {
      return <></>;
    }
  }

  locationOptionTemplate(location: Location | undefined) {
    const state = localStorage.getItem('current state') ?? '';
    if (location) {
      if (location.state_id === state) {
        return <span>{location.name}</span>;
      }
      return <span>{`${location.name} ${location.state_id}`}</span>;
    }
    return <></>;
  }

  setSelectedLocation(e: DropdownChangeParams, order: FreightOrder) {
    const value = e.value as number;
    if (this.props.changeDestinationLocation) {
      this.props.changeDestinationLocation(value, order.id ?? '');
    }
  }

  /**
   * Get location name from map.
   * @param order
   * @param taskTypes
   */
  getLocationName(order: FreightOrder, taskTypes: string[]): string | undefined {
    const orderId = order.id;
    const run = this.props.currentRun;
    if (!run) {
      return undefined;
    }
    const currentState = localStorage.getItem('current state') ?? '';
    const locationsMap = this.state.locationsMap;
    const task = run.tasks?.find(task => task.freight_order_id === orderId && taskTypes.includes(task.type));
    const stop = run.stops?.find(stop => stop.id === task?.stop_id);
    const location = locationsMap[stop?.location_id ?? ''];
    if (!location) {
      return undefined;
    }
    if (location.state_id === currentState) {
      return location.name;
    }
    return `${location.name} ${location.state_id}`;
  }

  fromBodyTemplate(order: FreightOrder) {
    if (this.props.currentRun) {
      return this.getLocationName(order, ['pickup']);
    }
    return <></>;
  }

  toBodyTemplate(order: FreightOrder) {
    if (this.props.currentRun) {
      return this.getLocationName(order, ['drop', 'final-drop']);
    }
    return <></>;
  }

  render() {
    const idFilter = (
      <InputText
        name="id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const companyFilter = (
      <InputText
        name="company"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const productLineFilter = (
      <MultiSelect
        value={this.state.filters.product_line}
        options={productLines}
        name="product_line"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
    const stageSelectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span className={`p-mr-1 stage-badge stage-badge-filter stage-${value.toLowerCase().replaceAll(' ', '-')}`}>
            {value}
          </span>
        );
      }

      return <></>;
    };
    const stageItemTemplate = (option: StageDropdownOption) => {
      return (
        <span className={`stage-badge stage-${option.value.toLowerCase().replaceAll(' ', '-')}`}>{option.value}</span>
      );
    };
    const freightStageFilter = (
      <MultiSelect
        selectedItemTemplate={stageSelectedItemTemplate}
        itemTemplate={stageItemTemplate}
        value={this.state.filters.freight_stage}
        options={stages}
        name="freight_stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const locationFilter = (
      <InputText
        name="location"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const containerFilter = (
      <InputText
        name="container"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const destinationFilter = (
      <InputText
        name="destination"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const runFilter = (
      <InputText
        name="run"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const table = (
      <TwoDataTable
        style={{height: '100%'}}
        key={this.props.id ?? ''}
        id={this.props.id ?? ''}
        pageSizeIdentifier={this.props.heightToScroll ? undefined : 'order_list_page_container' + this.props.id}
        heightToScroll={
          this.props.heightToScroll && this.props.heightToScroll !== 'max' ? this.props.heightToScroll : undefined
        }
        sizeIdentifiers={[]}
        loading={this.state.loading}
        value={this.state.items}
        totalRecords={this.state.totalItems}
        rows={this.state.pagination.pageSize}
        first={this.state.pagination.offset}
        sortField={this.state.sortBy?.field}
        sortOrder={this.state.sortBy?.order}
        onPage={e => this.onPageChange(e)}
        onSort={e => this.onSort(e)}
        activeFilters={this.state.activeFilters}
        selectionMode={this.props.hideMenu ? undefined : 'multiple'}
        selectedItems={this.getSelectedItems()}
        handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as FreightOrder[])}
        initMenuItems={this.props.hideMenu ? undefined : this.initTopMenuItems}
        customEmptyMessage={this.props.customEmptyMessage || undefined}
        paginatorClassName={this.props.paginatorClassName}
        columnHeaderClassName={this.props.columnHeaderClassName}
        columnClassName={this.props.selectionColumnClassName}
        hideFilter={this.props.hideFilter ?? false}
        showPaging={!this.props.hidePaging}
        showSelectAll={false}
      >
        {this.props.droppableId && <Column body={this.draggableBodyTemplate} />}
        {!this.props.isGroupped && (
          <Column
            header="Dealer"
            field="owner_company.account_number"
            body={this.dealerBodyTemplate}
            filter={!this.props.hideFilter}
            filterElement={companyFilter}
            sortable={!this.props.hideSort}
            showFilterMenu={false}
            style={{width: '100px'}}
            headerClassName={this.props.columnHeaderClassName}
          />
        )}

        {!this.props.isGroupped && (
          <Column
            header="Destination"
            field="destination"
            body={this.destinationBodyTemplate}
            filter={!this.props.hideFilter}
            filterElement={destinationFilter}
            sortable={!this.props.hideSort}
            showFilterMenu={false}
            style={{width: '100px'}}
            headerClassName={this.props.columnHeaderClassName}
          />
        )}

        <Column
          header="Reference"
          field="order.reference"
          body={this.referenceBodyTemplate}
          filter={!this.props.hideFilter}
          filterElement={referenceFilter}
          sortable={!this.props.hideSort}
          showFilterMenu={false}
          style={{width: '150px'}}
          headerClassName={this.props.columnHeaderClassName}
        />
        <Column
          header="Code"
          field="id"
          filter={!this.props.hideFilter}
          filterElement={idFilter}
          sortable={!this.props.hideSort}
          showFilterMenu={false}
          style={{width: '100px'}}
          headerClassName={this.props.columnHeaderClassName}
        />
        <Column
          header="# Boxes"
          field="size"
          sortable={!this.props.hideSort}
          body={this.sizeBodyTemplate}
          style={{width: '50px'}}
          showFilterMenu={false}
          headerClassName={this.props.columnHeaderClassName}
        />
        <Column
          header="Current Location"
          field="location"
          body={this.locationBodyTemplate}
          filter={!this.props.hideFilter}
          filterElement={locationFilter}
          sortable={!this.props.hideSort}
          showFilterMenu={false}
          style={{width: '100px'}}
          headerClassName={this.props.columnHeaderClassName}
        />
        <Column
          header="Stage"
          field="stage"
          body={this.stageBodyTemplate}
          filter={!this.props.hideFilter}
          filterElement={freightStageFilter}
          sortable={!this.props.hideSort}
          showFilterMenu={false}
          style={{width: '50px'}}
          headerClassName={this.props.columnHeaderClassName}
        />
        <Column
          header="Run"
          field="run"
          body={this.runBodyTemplate}
          filter={!this.props.hideFilter}
          filterElement={runFilter}
          showFilterMenu={false}
          style={{width: '150px'}}
          headerClassName={this.props.columnHeaderClassName}
        />
        <Column
          header="Product"
          field="factory_order.product_line"
          body={this.productLineBodyTemplate}
          filter={!this.props.hideFilter}
          filterElement={productLineFilter}
          sortable={!this.props.hideSort}
          showFilterMenu={false}
          style={{width: '100px'}}
          headerClassName={this.props.columnHeaderClassName}
        />
        {this.props.subtableOfTable !== 'runs' && (
          <Column
            header="Age"
            field="age"
            sortable={!this.props.hideSort}
            body={this.ageBodyTemplate}
            style={{width: '50px'}}
            showFilterMenu={false}
            headerClassName={this.props.columnHeaderClassName}
          />
        )}

        {((this.props.isAll && !this.props.ordersDestinationLocations) || this.props.subtableOfTable === 'runs') && (
          <Column
            header="Container"
            field="container.id"
            filter={!this.props.hideFilter}
            filterElement={containerFilter}
            sortable={!this.props.hideSort}
            showFilterMenu={false}
            style={{width: '150px'}}
            headerClassName={this.props.columnHeaderClassName}
          />
        )}
        {this.props.ordersDestinationLocations && (
          <Column
            header="Run Destination"
            field=""
            body={this.changeDestinationBodyTemplate}
            showFilterMenu={false}
            style={{width: '150px'}}
            headerClassName={this.props.columnHeaderClassName}
          />
        )}
        {this.props.currentRun && (
          <Column
            header="From"
            field="from"
            showFilterMenu={false}
            style={{width: '100px'}}
            headerClassName={this.props.columnHeaderClassName}
            body={this.fromBodyTemplate}
          />
        )}
        {this.props.currentRun && (
          <Column
            header="To"
            field="to"
            showFilterMenu={false}
            style={{width: '100px'}}
            headerClassName={this.props.columnHeaderClassName}
            body={this.toBodyTemplate}
          />
        )}
        {this.props.isAll && (
          <Column
            header="Last Action"
            field="last_activity"
            body={this.lastActivityBodyTemplate}
            sortable
            style={{width: '230px'}}
            showFilterMenu={false}
          />
        )}
      </TwoDataTable>
    );

    return (
      <div
        key={'key-order_list_page_container' + this.props.id ?? ''}
        id={'order_list_page_container' + this.props.id ?? ''}
        className="page-container"
      >
        {this.props.droppableId ? (
          <Droppable
            droppableId={this.props.droppableId ?? ''}
            renderClone={(provided, snapshot, rubric) => {
              const order = JSON.parse(rubric.draggableId) as FreightOrder;
              return (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  {order?.id ?? ''}
                </div>
              );
            }}
          >
            {provided => (
              <div ref={provided.innerRef}>
                {table}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          table
        )}

        {!this.props.hideMenu && !this.props.customMenuItems && (
          <AssignOrdersToRunDialog
            showDialog={this.state.showAssignToRunDialog}
            onHide={() => this.setState({showAssignToRunDialog: false})}
            toast={this.toast}
            freightOrders={this.getSelectedItems()}
          />
        )}

        <DeliverUndeliverDialog
          showDialog={this.state.showDeliverDialog}
          type={this.state.deliverDialogType}
          onCancel={() => this.setState({showDeliverDialog: false})}
          onContinue={() => {
            this.setState({showDeliverDialog: false});
          }}
          toast={this.toast}
          orders={this.getSelectedItems()}
        />

        <ChangeLocationDialog
          showDialog={this.state.showChangeCurrentLocationDialog}
          onHide={() => this.setState({showChangeCurrentLocationDialog: false})}
          toast={this.toast}
          freightOrders={this.getSelectedItems()}
        />
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default OrderListComponent;
